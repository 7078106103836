.fontFamily {
  font-family: Georgia, sans-serif;
}
.background {
  padding: 20px;
  background: linear-gradient(300deg, #fc90cf, #fe0f3e);
  padding-bottom: 100px;
}
.firstContainer {
  margin-bottom: 20px;
  width: 90vw;
}
.course1Title {
  font-size: 30px;
  color: #fff;
  font-family: Georgia;
  margin-left: 40px;
  text-align: center;
  border-width: 0;
}
.firstRow {
  display: flex;
  flex-direction: row;
}
.gridContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.contForText {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  border-width: 1px;
  border-radius: 40px;
  border-color: #000;
  border-style: solid;
  width: 43vw;
  font-weight: lighter;
  margin-left: 30px;
  background: linear-gradient(#fff, #fff);
  box-shadow: 1px 4px 6px #555;
  margin-right: 30px;
  height: auto;
  align-items: center;
  justify-content: center;
}
.firstParagraph {
  font-size: 24px;
  line-height: 1.6;
  padding: 30px;
  margin-left: 20px;
  padding-bottom: 30px;
}
.image {
  width: 300px;
  height: 300px;
  border-radius: 40px;
  margin-left: 13vw;
  margin-top: 6vh;
}
.secondRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.saveContain {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.editButton {
  width: 10vw;
  border-radius: 8px;
  border-width: 0;
  cursor : pointer;
  height: 4.5vh;
  background-color: #000;
  color : #fff;
  margin-top: 1.5vh;
  margin-bottom: 2vh;
}
.editTextArea {
  width: 40vw;
  height: 41vh;
  border-radius: 20px; 
  margin-top: 2.1vh;
  font-size: 1.5vw;
  font-family: 'Courier New', Courier, monospace;
}
.textareacont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.saveButton {
  width: 15vw;
  margin-bottom: 2.1vh;
  background-color: #000;
  color : #fff;
  border-width: 0;
  cursor : pointer;
  border-radius: 10px;
  height: 3vh;
}
@media only screen and (max-width: 600px) {
  .fontFamily {
    display: flex;
    flex-direction: column;
  }
  .gridContainer {
    display: flex;
    flex-direction: column;
  }
  .course1Title {
    font-size: 3.5vh !important;
    color: #fff;
    font-family: Georgia;
    text-align: center;
    margin: 0;
    border: none;
    border-width: 0;
  }
  .background {
    display: flex;
    flex-direction: column;
  }
  .contForText {
    margin: 0 !important;
    height: auto;
    width: auto;
  }
  .firstParagraph {
    font-size: 2.5vh !important;
    padding: 2vh !important;
    margin: 0;
    display: flex;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: auto;
  }
  .saveContain {
    margin-top: 2vh;
  }
  .secondCont {
    height: auto;
    width: auto;
    margin: 0;
    margin-top: 3vh;
  }
  .secondContParagraph {
    font-size: 2.5vh;
    line-height: 1.6;
    color: #000;
  }
  .thirdCont {
    width: auto;
    height: auto;
    margin-left: 0;
  }
  .thridContParagraph {
    font-size: 2.5vh;
    line-height: 1.6;
    color: #000;
  }
  .image {
    width: 34vw;
    height: auto;
    margin: 0;
  }
  .firstRow {
    flex-direction: column;
  }
  .secondRow {
    display: flex;
    flex-direction: column;
  }
}
