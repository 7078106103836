.course-container {
  font-family: "Georgia, sans-serif";
  height: auto;
}

.course-content {
  background-color: #1fd0fc;
  padding-bottom: 100px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.course-title {
  margin-bottom: 3vh;
  width: 90vw;
  text-align: center;
}

.course-title p {
  font-size: 30px;
  color: #fff;
  font-family: "Georgia";
  text-align: center;
}

.course-info {
  display: flex;
  flex-direction: row;
}

.info-box {
  margin-bottom: 20px;
  border-width: 1px;
  border-radius: 40px;
  border-color: #000;
  border-style: solid;
  width: 43vw;
  font-weight: lighter;
  background: linear-gradient(#fff, #fff);
  box-shadow: 1px 4px 6px #555;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 2vw;
  margin-left: 2vw;
}

.info-box p {
  font-size: 24px;
  line-height: 1.6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.academy-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.academy-info p {
  font-size: 27px;
  font-family: monospace;
  color: Red;
  font-weight: bold;
}
.addButtonCont {
  position: absolute;
  top: 102.5vh;
  width: auto;
  height: auto;
  left: 2vw;
}
.course3Add {
width: 5vw;
height: 6vh;
border-radius: 1vw;
border-color: #fff;
border-width: 0;
cursor: pointer;
background-color: rgb(248, 13, 13);
color: #fff;
font-size: 18px;
font-family: Georgia;
}
.editTextArea3 {
  width: 35vw;
  height: 50vh;
  border-radius: 20px; 
  margin-top: 2.1vh;
  font-size: 1.5vw;
  font-family: 'Courier New', Courier, monospace;
}
@media only screen and (max-width: 600px) {
  .course-title {
    font-size: 4vh;
    margin: 0;
    text-align: center;
  }
  .course-content {
    padding: 0;
  }
  .course-info {
    flex-direction: column;
    padding: 0;
    margin: 0;
    padding-left: 5vh;
    padding-right: 5vh;
  }
  .info-box {
    width: auto;
    height: auto;
    padding: 0;
    margin: 0;
  }
  .info-box p {
    font-size: 2.7vh;
    padding-left: 2vh;
    padding-right: 2vh;
  }
  .academy-info {
    padding-top: 2vh;
    padding-bottom: 2vh;
  }
}
