.createButton {
  position: absolute;
  right: 3%;
  top: 105%;
  width: 10vw;
  height: 7vh;
  border-radius: 1vw;
  border-color: #fff;
  cursor: pointer;
  background-color: #ba39ff;
  color: #fff;
  font-size: 18px;
  font-family: Georgia;
}
.news-form2 {
  max-width: 60vw;
  padding-top: 20px;
  padding: 1px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: auto;
  font-family: monospace;
}
.textContent {
  font-family: Georgia;
  width: 40%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-left: 4vw;
  font-size: 23px;
}
.carouselArrow {
  right: 0%;
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
}
.editNewsButton {
  margin-bottom: auto;
  height: 5vh;
  width: auto;
  background-color: #fff;
  border : 0;
  cursor: pointer;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 1.3vw;
}
.carouselArrow2 {
  left: 0%;
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  border: none;
  background: none;
  outline: none;
}
.firstCont {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 7vh;
}
.carousel1 {
  padding: 20px;
  width: 50vw;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 6vw;
}
.secondContain {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.carousel2 {
  padding: 20px;
  width: 40vw;
  padding-right: 5vw;
}
.textContent2 {
  font-family: Georgia;
  width: 40%;
  font-size: 23px;
  margin-right: 20vh;
}
.thirdContain {
  display: flex;
  flex-direction: row;
  padding-bottom: 10vh;
  align-items: center;
}
.thirdImage {
  width: 500px;
  height: 400px;
  border-radius: 20px;
  margin-left: 10vw;
}
.textContent3 {
margin-left: 10vh;
  font-family: Georgia;
  font-size: 23px;
  width: 40%;
  text-align: center;
}
.createdNewsCont {
  display: flex;
  flex-direction: row;
  width: 80vw;
  height: auto;
  font-family: Georgia;
  align-items: center;
  justify-content: center;
  margin-top: 6vh;
}
.createdNewsContTop {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}
.createdNewsLittleCont {
  width: 45vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 4vh;
}
.createdTitle {
  font-size: 2vw;
  color: #3996ff;
}
.createdPara {
  margin-top: 2vw;
  font-size: 1.8vw;
  max-width: 100vw; /* Limits the paragraph width */
  overflow-wrap: break-word; /* Ensures long words break to the next line */
  box-sizing: border-box; /* Ensures padding is included in the element's width */
}

.createdNewsImageRight {
  width: 40vw;
  height: auto;
  border-radius: 1vw;
  margin-left: 4vw;
}
.form-group textarea {
  height: 22vh;
}
.form-group2 {
  margin-bottom: 20px;
}
.form-group2 textarea {
  height: 11vh;
}
.createdNewsImageLeft {
  width: 40vw;
  height: auto;
  border-radius: 1vw;
  margin-right: 4vw;
  margin-left: 5vw;
}
.createdNewsImageFull {
  width: 80vw;
  height: 80vh; 
  border-radius: 3vw;
}
.deleteButton {
    width: auto;
    height: 5vh;
    padding: 10px;
    border: 0;
    background-color: #fff;
    cursor: pointer;
    color: #FB1B1B;
    font-size: 1.2vw;
    font-family: Georgia;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: auto;
    font-size: 1.3vw;
}
.deleteButtonTop {
  width: auto;
  height: 5vh;
  padding: 10px;
  border: 0;
  background-color: #fff;
  cursor: pointer;
  color: #FB1B1B;
  font-size: 1.2vw;
  font-family: Georgia;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: auto;
  margin-right: auto;
}
.buttonsRow { 
  display: flex;
  flex-direction: row;
  margin-bottom: auto;
}
.buttonsRow2 { 
  display: flex;
  flex-direction: row;
  margin-bottom: auto;
}
.showButtonss{
  width: 6vw;
  height: 5vh;
  border-radius: 0.7vw;
  border-color: #fff;
  cursor: pointer;
  background-color: #fff;
  color: #fff;
  font-size: 1.2vw;
  font-family: Georgia;
}
.createSection {
  width: 50%;
}
.news-form {
  max-width: 60vw;
  padding-top: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: auto;
  font-family: 'Courier New', Courier, monospace;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
}

input[type="file"] {
  padding: 10px;
}

.submit-btn {
  background-color: #4CAF50;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: #3e8e41;
}
@media only screen and (max-width: 600px) {
  .firstCont {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    width: 90vw;
  }
  .createButton {
    width: 14vw;
    font-size: 2vh;
    border-radius: 10px;
    top: 103vh;
  }
  .textContent {
    font-size: 2vh;
    width: 70vw;
    text-align: center;
    margin: 0;
    margin-bottom: 3vh;
  }
  .carousel1 {
    width: 80vw;
  }
  .firstCont {
    padding-top: 2vh !important;
  }
  .secondContain {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    width: 90vw;
    margin-top: 4vh;
  }
  .carousel2 {
    width: 65vw;
  }
  .textContent2 {
    font-size: 2vh !important;
    text-align: center;
    padding: 0;
    margin: 0;
    width: 80vw;
    margin-bottom: 3vh;
  }
  .thirdContain {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    margin-top: 4vh;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    width: 90vw;
    margin-bottom: 3vh;
  }
  .thirdImage {
    width: 70vw;
    height: auto;
    margin: 0;
    margin-top: 2vh;
  }
  .textContent3 {
    font-size: 2vh;
    width: 80vw;
    text-align: center;
    margin-top: 3vh;
    padding-bottom: 3vh;
    margin-left: 0;
  }
  .createSection {
    width: 80vw;
  }
  .createdNewsCont {
    display: flex;
    flex-direction: column;
    width: 90vw;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    margin-bottom: 3vh;
  }
  .createdNewsImage {
    width: 65vw;
    margin-bottom: auto;
    padding: 0;
    margin: 0;
    margin-bottom: 3vh;
  }
  .createdTitle {
    margin-top: -3vh;
    font-size: 3.2vh;
  }
  .createdPara {
    font-size: 2vh;
    height: auto;
    width: 60vw !important;
  }
  .deleteButton {
    width: 12vw;
    height: 4.5vh;
    font-size: 2vh;
    border-radius: 2vh;
    margin-right: auto;
    margin-left: 2vh;
    margin-top: 2vh;
  }
  .buttonsRow {
    margin-left: auto;
    display: flex;
    flex-direction: row;
  }
  .buttonsRow2 {
    flex-direction: row;
    margin-bottom: 2vh;
    margin-top: -2vh;
    margin-right: auto;
  }
  .editNewsButton {
    font-size: 2vh;
    height: 5vw;
    margin: 0;
    margin-top:2vh;
    margin-right: 1vh;
  }
  .deleteButton {
    height: 5vw;
    font-size: 2vh;
  }
  .deleteButtonTop {
    height: 5vw;
    font-size: 2vh;
  }
  .createdNewsImageFull {
    width: 90vw;
    height: auto;
    margin-bottom: 2vh;
    margin-top: 2vh;
  }
  .createdNewsImageRight {
    width: 80vw;
    border-radius: 2vh;
    margin-bottom: 2vh;
  }
  .createdNewsImageLeft {
    width: 80vw;
    border-radius: 2vh;
    margin-top: 2vh;
    margin-bottom: 2vh;
  }
  .createdTitle {
    margin-top: 1vh;
  }
}
