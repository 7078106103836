.benCont {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.cardB {
    border: 1px solid #000;
    border-radius: 40px;
    box-shadow: 1px 4px 6px #555;
    padding: 20px;
    margin-top: 50px;
    width: 80vw;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .createButtonBenefits {
    position: absolute;
    right: 3%;
    top: 102%;
    width: 7vw;
    height: 7vh;
    border-radius: 1vw;
    border-color: #fff;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    font-size: 18px;
    font-family: Georgia;
  }
  .contentBenefit {
    font-family: Georgia, 'Times New Roman', Times, serif;
    width: 60vw;
    margin-top: 1.5vh;
    font-size: 1.55vw;
    margin-right: 5vw;
  }
  .titleBenefit { 
     font-family: Georgia, 'Times New Roman', Times, serif;
     text-align: center;
     font-size: 2vw;
     margin-top: 2vh;
     color : blue
  }
  .deleteBenefit {
    width: 6vw;
    height: 5vh;
    border-radius: 0.7vw;
    border-color: #fff;
    cursor: pointer;
    background-color: #FB1B1B;
    color: #fff;
    font-size: 1.2vw;
    font-family: Georgia;
    margin-top: 2vh;
  }
  .saveBenefit {
    width: 12vw;
    height: auto;
    border-radius: 0.7vw;
    border-color: #fff;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    font-size: 1.2vw;
    font-family: Georgia;
    margin-top: 2vh;
    padding: 10px;
  }
