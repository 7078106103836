.profile-header {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 19vh;
  width: 100%;
}
.header-logo-cont {
  display: flex;
  align-items: center;
  margin-left : 20px;
  margin-top : 5px;
  margin-bottom : 5px;
}
.header-yeti-logo {
  width: 70px;
  border-radius: 20px;
  margin-right: 10px;
}
.header-yeti-logo-text {
   color: #000;
    font-family: Georgia, serif;
    font-size: 20px;
    
}
.profile-body {
  display: flex;
  flex-direction: row;
}

.profile-info {
  width: 60%;
  height: 100%;
  display: flex;
}

.profile-info-container {
  width: 100%;
  height: 100%;
}

.profile-name {
  font-size: 40px;
  margin-left: 30%;
  margin-top: 17%;
  font-family: Georgia;
}

.profile-email {
  font-size: 20px;
  margin-left: 25%;
  margin-top: 15px;
  font-family: Georgia;
}

.profile-age {
  font-size: 20px;
  margin-left: 25%;
  margin-top: 15px;
  font-family: Georgia;
}

.profile-role {
  font-size: 20px;
  margin-left: 25%;
  margin-top: 15px;
  font-family: Georgia;
}

.profile-hr {
  width: 50%;
  margin-left: 15%;
  margin-top: 2%;
}

.profile-divider {
  height: 83.5vh;
}

.profile-picture-container {
  display: flex;
  justify-content: center;
  width: 40%;
  height: auto;
}

.profile-picture {
  width: 135px;
  height: 135px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: auto;
  margin-bottom: 5vh;
}

.profile-picture-name {
  font-family: Georgia;
  margin-top: 10px;
  font-size: 20px;
}
.edit-profile-button {
  width: 150px;
  height: 38px; /* Increased height from 30px to 45px */
  border-radius: 10px;
  border-color: #fff;
  border-style: solid;
  border-width: 1px;
  background-color: #2790FF;
  color: white;
  font-size: 18px; /* Increased font size from 17px to 20px for better readability */
  font-family: Georgia;
  cursor: pointer;
  margin-top: 20px;
}
.log-out-button {
  width: 150px;
  height: 38px; /* Increased height from 30px to 45px */
  border-radius: 10px;
  border-color: #fff;
  border-style: solid;
  border-width: 1px;
  background-color: #FF3939;
  color: white;
  font-size: 18px; /* Increased font size from 17px to 20px for better readability */
  font-family: Georgia;
  cursor: pointer;
  margin-top: 20px;
}
.buttoncontainer {
  margin-left: 40px;
}

.link-button {
  text-decoration: none;
  width: 100%;
  color: #000;
  padding: 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 25px;
  text-decoration: underline;
  text-align: center;
}

.link-button:hover {
  background-color: #FF7A7A;
}
.user-profile-cont {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: 40px;
}
.user-profile {
  width: 65px;
  height: 65px;
  border-radius: 50%;
}
.profile-picture-picture {
  width: 190px;
  height: auto;
  border-radius: 50%;
}
@media only screen and (max-width: 600px) {
  .profile-header {
    flex-direction: column;
    height: 26vh;
    width: 100vw;
  }
  .buttoncontainer {
    margin-left: 40px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .header-yeti-logo-text {
    text-align: center;
  }
  .header-logo-cont {
    margin-left : 0 !important
  }
  .user-profile-cont {
    position: absolute;
    top: 1vh;
    left: -1001vw;
  }
  .link-button {
    margin: 0;
  }
  .buttoncontainer {
    margin: 0;
  }
  .profile-divider {
    height: 0;
    margin-top: 2vh;
  }
  .profile-body {
    flex-direction: column;
    height: auto;
  }
  .profile-picture-picture {
    position: absolute;
    top: 28vh;
    width: 34vw;
    right: 33vw;
  }
  .profile-info {
    margin-top: 40vw;
    width: 100%;
    margin-left: 0 !important;
    padding: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .profile-email {
    margin-left: 5vw;
    padding-top: 1.5vh;
    width: 90vw;
    margin-top: 0;
  }
  .profile-name {
    margin: 0;
  }
  .profile-age {
    margin: 0;
    padding-top: 1vh;
  }
  .profile-role {
    margin: 0;
    padding-top: 1vh;
  }
  .profile-hr {
    width: 0;
    position: absolute;
    left: -100vw;
  }
  .profile-picture-name {
    color : #fff
  }
  .profile-picture-container {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100vw;
  }
  .edit-profile-button {
    margin-top: 0;
  }
}