header {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.yetiTitle {
  font-size: 1.5vw;
}

.yetiLogo {
  width: 5.5vw;
  border-radius: 2vh;
  margin-right: 1vw;
}
.profileImage {
  width: 10vh;
  height: 10vh;
  border-radius: 50%;
  margin-top: 2.5vh;
}
.profileName {
  font-size: 1.5vw;
  font-family: Georgia;
  margin-top: 3px;
  color: #fff;
}
.inner-div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 90vw;
  font-size: 50px;
  color: #fff;
  font-family: Georgia, serif;
  text-align: center;
  height: 100%;
  flex-direction: column;
}
.overlay {
  margin-bottom: auto;
  width: 12vw;
  height: 0;
  border-radius: 20px;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: height 0.5s ease-in-out;
  z-index: 1;
}

.menu {
  background-color: #333;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 8px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.menu button {
  margin-bottom: 10px;
  padding: 12px;
  background-color: none;
  font-size: 18px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
}

.menu button:hover {
  background-color: #777;
}

.overlay.show {
  height: 46vh;
  overflow: hidden;
}

.overlay.show .menu {
  opacity: 1;
  transform: translateY(0);
}

.menu-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.logo {
  font-size: 24px;
}

.menu-button.close {
  display: none;
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

.overlay.show .menu-button.open {
  display: none;
}

.overlay.show .menu-button.close {
  display: block;
}
.custom-menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: none;
  justify-content: center;
  border-radius: 10px;
  margin-left: 30px;
}

.custom-menu-button {
  text-decoration: none;
  width: "400px";
  color: #fff;
  padding: 12px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 1.8vw;
  margin-bottom: 10px;
  text-decoration: underline;
}
.custom-second-menu-button {
  width: 100%;
  display: flex;
  text-decoration: none;
  color: #fff;
  padding-top: 20px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  font-family: Georgia, "Times New Roman", Times, serif;
  font-size: 2.5vh;
  margin-bottom: 10px;
  text-decoration: underline;
  justify-content: center;
  text-align: center;
  align-items: center;
}
.custom-menu-button:hover {
  background-color: #fe6262;
}
.custom-second-menu-button:hover {
  background-color: #fe6262;
}
.imported-blur-background {
  color: #fff;
  margin-bottom: 2vh;
}

.imported-blur-background::before {
  width: 100%;
  height: 40%;
  z-index: -1;
  border-radius: 20px;
}
.scroll-animation {
  animation: scrollAnimation 1.2s ease forwards;
}

@media only screen and (max-width: 600px) {
  .custom-menu {
    width: 10vw;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, auto);
    position: absolute;
    top: 16vh;
    left: 44%;
    transform: translateX(-50%);
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  .header {
    padding: 0 !important;
    margin: 0 !important;
  }
  .custom-menu-button {
    margin-right: 0;
    margin-bottom: 10px;
    font-size: 2.8vh !important;
  }

  .menu {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .custom-second-menu-button {
    padding: 10px 20px;
  }
  .bigTitle {
    font-size: 5vh;
    color: #fff;
    margin-top: 2vh;
    text-align: center;
    z-index: 1;
  }
  .inner-div {
    margin-bottom: 10vh !important;
  }
  .yetiLogo {
    width: 8.5vh;
  }
  .yetiTitle {
    font-size: 2.3vh;
  }
  .profileImage {
    width: 8.5vh;
    height: 8.5vh;
  }
  .profileName {
    font-size: 2vh;
  }
  .buttonText {
    width: 70vw !important;
  }
  .dropdown-content {
    width: 65vw;
    left: -5.5vh !important;
    height: 5vh;
    margin-top: 3vh;
    z-index: 1 !important;
  }
  .bigTitle {
    margin-top: 17vh;
  }
  .menu {
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin: 0;
    align-items: center;
    justify-content: center;
    width: 30vw;
    height: auto;
  }
  .custom-second-menu-button {
    font-size: 2vh;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    margin-top: 1vh;
  }
}

@keyframes scrollAnimation {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.dropdown-content {
  position: absolute;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  opacity: 0;
  transform: translateY(-10px);
  width: 12vw;
  top: 7.5vw;
  left: 25%;
}
.dropdown-content.show {
  animation: dropdown-animation-open 0.4s ease-in-out forwards;
}

.dropdown-content.close {
  animation: dropdown-animation-close 0.3s ease-in-out forwards;
}

@keyframes dropdown-animation-open {
  0% {
    opacity: 0;
    transform: translateY(-30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes dropdown-animation-close {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(-50px);
  }
}
.buttonText {
  width: 50vh;
  height: 6vh;
  border-radius: 20px;
  background-color: #ff3737;
  color: #ffffff;
  font-size: 18px;
  cursor: pointer;
  font-family: Georgia;
  border: 0;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease-in-out;
  font-size: 3.5vh;
  margin-top: 5vh;
  flex-direction: row;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonText:hover {
  background-color: #ff2323;
}

.bigTitle {
  width: 80vw;
  font-size: 5vw;
  color: #fff;
  text-align: center;
}
